import orders from '../database/orders.json';
import restaurants from '../database/restaurants.json';
import { SimilarRestaurants } from '../types';

/**
 * Find similar restaurants based on item similarity.
 * @param restaurantId The ID of the restaurant to find similar restaurants for.
 * @returns An array of restaurants that have the most similar items.
 */
export const findSimilarRestaurants = (restaurantId: number, supplierId: number): SimilarRestaurants[] => {
  const targetRestaurant = restaurants.find((r) => r.id === restaurantId);

  if (!targetRestaurant) {
    throw new Error('Restaurant not found');
  }

  const getSimilarityScore = (items1: number[], items2: number[]): number => {
    const set1 = new Set(items1);
    const set2 = new Set(items2);
    let commonItems = 0;

    set2.forEach((item) => {
      if (set1.has(item)) {
        commonItems++;
      }
    });

    return commonItems;
  };

  const similarRestaurants = restaurants
    .filter((r) => r.id !== restaurantId) // Exclude the target restaurant
    .map((r) => ({
      restaurant: r,
      similarityScore: getSimilarityScore(targetRestaurant.items, r.items),
    }))
    .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score
    .map((result) => result.restaurant); // Return only the restaurant objects

  const updatedData = similarRestaurants.map((r) => {
    const isCustomer = ((): boolean => {
      // Check if there's any order where both supplierId and restaurantId match
      const customerOrderExists = orders.some(
        (order) => order.supplierId === supplierId && order.restaurantId === r.id
      );

      return customerOrderExists;
    })();

    const averageRating = ((): number => {
      const { suppliersRating } = r;

      // Step 1: Sum all the ratings
      const totalRatings = suppliersRating.reduce((total, supplier) => total + supplier.rating, 0);

      // Step 2: Calculate the average rating
      const averageRating = totalRatings / suppliersRating.length;

      // Return the average rating, rounded to one decimal place
      return Math.round(averageRating * 10) / 10; // Round to 1 decimal place
    })();

    return {
      ...r,
      averageRating,
      isCustomer,
    };
  });

  return updatedData;
};
