import cn from 'classnames';
import { FullPageSpinner } from 'components/full-page-spinner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandLogo } from '../components/brand-logo';
import { RestaurantData } from '../components/restaurant-data';
import { SearchInput } from '../components/search-input';
import { getRestaurantById } from '../server/get-restaurant-by-id';
import { RestaurantAnalysis } from '../types';

export const RestaurantDetailsScreen = () => {
  const { supplierId, restaurantId } = useParams<{ supplierId: string; restaurantId: string }>();
  const [restaurant, setRestaurant] = useState<RestaurantAnalysis>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!restaurantId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      const result = getRestaurantById(Number(restaurantId), Number(supplierId));

      if (typeof result !== 'string') {
        setRestaurant(result);
      }
    } catch (error) {
      alert(error as string);
      navigate(`/restaurant-details/${supplierId}`, { replace: true });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [restaurantId]);

  if (loading) return <FullPageSpinner />;
  return (
    <div className='container mx-auto pb-[200px]'>
      {/* Logo */}
      <div className='py-3xl'>
        <BrandLogo />
      </div>

      {/* Search */}
      <div className={cn('flex flex-col items-center', { 'mt-[200px]': !restaurantId })}>
        <div className='mt-md'>
          <SearchInput defaultValue={restaurant?.name} />
        </div>
      </div>

      {/* Data */}
      <div className={cn('mt-[60px]', { hidden: !restaurantId })}>
        <RestaurantData restaurant={restaurant} />
      </div>
    </div>
  );
};
