import { AutoComplete, Image, Tag } from 'antd';
import cn from 'classnames';
import { useCallback, useState } from 'react';
// import { HiOutlineSearch } from 'react-icons/hi';
import _debounce from 'lodash/debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { searchRestaurantsByName } from '../server/search-restaurants-by-name';
import { RestaurantSearch } from '../types';

interface SearchInputProps {
  defaultValue?: string;
}

export const SearchInput = ({ defaultValue }: SearchInputProps) => {
  const navigate = useNavigate();
  const { supplierId, restaurantId } = useParams<{ supplierId: string; restaurantId: string }>();
  const [options, setOptions] = useState<RestaurantSearch[]>([]);

  const debouncedFetchData = useCallback(
    _debounce(async (searchText: string) => {
      try {
        const result = await searchRestaurantsByName(searchText, Number(supplierId));

        const newOptions: RestaurantSearch[] = result.map((restaurant: RestaurantSearch) => restaurant);

        setOptions(newOptions);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    }, 500),
    []
  );

  const handleSearch = (searchText: string) => {
    if (searchText) {
      debouncedFetchData(searchText);
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (restaurantId: number) => {
    navigate(`/restaurant-details/${supplierId}/${restaurantId}`, { replace: true });
  };

  const onClear = () => {
    navigate(`/restaurant-details/${supplierId}`, { replace: true });
  };

  const renderOptionLabel = (restaurant: RestaurantSearch) => (
    <div className='flex items-center gap-sm'>
      <Image
        src={restaurant.image}
        preview={false}
        width={60}
        fallback='https://placehold.co/400?text=KASO&font=roboto'
      />

      <div className='flex flex-col gap-[1px]'>
        <h2 className='font-semibold text-md'>{restaurant.name}</h2>
        <p className='text-default-500 text-[14px]'>Type: {restaurant.cuisineType}</p>
        <div className='flex gap-md text-default-500 text-[14px]'>
          <div>
            <p>Locations: {restaurant.numberOfLocations}</p>
          </div>
          <div className='flex items-center gap-2'>
            <p>Is a customer:</p>
            <Tag
              color={restaurant.isCustomer ? 'green' : 'red'}
              bordered={true}
              className='text-[10px] leading-[15px] px-[5px]'>
              {restaurant.isCustomer ? 'Yes' : 'No'}
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h1 className={cn({ 'text-5xl': !restaurantId, 'text-2xl': restaurantId })}>Find a Restaurant</h1>
      <AutoComplete
        style={{
          width: !restaurantId ? 700 : 500,
          height: !restaurantId ? 60 : 40,
        }}
        options={options.map((option) => ({
          key: option.id,
          value: option.name,
          label: renderOptionLabel(option),
        }))}
        defaultValue={defaultValue}
        onSearch={handleSearch}
        onSelect={(_, option) => handleSelect(option.key)}
        className={cn({ 'mt-lg': !restaurantId, 'mt-2': restaurantId })}
        placeholder='Write a restaurant name'
        // prefix={<HiOutlineSearch className='stroke-default-300' />}
        allowClear
        onClear={onClear}
      />
    </div>
  );
};
