export const formatPrice = (price: number, currencyCode?: string): string => {
  const priceStr: string = price?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (currencyCode) {
    return `${currencyCode} ${priceStr}`;
  }

  return priceStr;
};
