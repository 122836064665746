import type { ProgressProps, TableProps } from 'antd';
import { Alert, Button, Image, Modal, Progress, Rate, Table, Tag, Typography } from 'antd';
import cn from 'classnames';
import { TextCell } from 'components/text-cell';
import { useDisclosure } from 'hooks/use-disclosure';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';
import { RiEmotionHappyLine, RiEmotionUnhappyLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { formatPrice } from 'utils/format-price';
import { findSimilarRestaurants } from '../server/find-similar-restaurants';
import { Restaurant, RestaurantAnalysis, SimilarRestaurants } from '../types';
import { RestaurantSalesChart } from './restaurant-sales-chart';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#FF0000',
  '100%': '#008000',
};

const twoColors1: ProgressProps['strokeColor'] = {
  '0%': '#87ceeb',
  '100%': '#008000',
};

interface RestaurantDataProps {
  restaurant?: RestaurantAnalysis;
}

export const RestaurantData = ({ restaurant }: RestaurantDataProps) => {
  const navigate = useNavigate();
  const { supplierId } = useParams<{ supplierId: string }>();
  const [similarRestaurants, setSimilarRestaurants] = useState<SimilarRestaurants[]>([]);
  const rightSideElementRef = useRef<HTMLDivElement>(null);
  const [rightSideWidth, setWidth] = useState<number>(0);

  const { isOpen: isOpenSamModal, onClose: onCloseSamModal, onOpen: onOpenSamModal } = useDisclosure();
  const { isOpen: isOpenRfqModal, onClose: onCloseRfqModal, onOpen: onOpenRfqModal } = useDisclosure();
  const { isOpen: isOpenReviewsModal, onClose: onCloseReviewsModal, onOpen: onOpenReviewsModal } = useDisclosure();

  // Get similar restaurants
  useEffect(() => {
    if (!restaurant) return;
    const result = findSimilarRestaurants(restaurant?.id, Number(supplierId));
    setSimilarRestaurants(result);
  }, [restaurant]);

  // Get right side width
  useEffect(() => {
    if (rightSideElementRef.current) {
      setWidth(rightSideElementRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (rightSideElementRef.current) {
        setWidth(rightSideElementRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onSimilarRestaurantClick = (similarRestaurant: Restaurant) => {
    navigate(`/restaurant-details/${supplierId}/${similarRestaurant.id}`, { replace: true });
  };

  interface ItemsTheyOrderFromYou {
    key: number;
    name: string;
    category: string;
    price: string;
    lastMonthSales: string;
    yearTodaySales: string;
    frequency: number;
  }

  const itemsTheyOrderFromYouColumns: TableProps<ItemsTheyOrderFromYou>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text) => <TextCell value={text} />,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => <TextCell value={text} minWidth={70} />,
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      render: (text) => <TextCell value={text} minWidth={50} />,
    },
    {
      title: 'Last Month Sales',
      dataIndex: 'lastMonthSales',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'YTD Sales',
      dataIndex: 'yearTodaySales',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Frequency/Month',
      dataIndex: 'frequency',
      render: (text) => <TextCell value={text} />,
    },
  ];

  const itemsTheyOrderFromYouData = useMemo<ItemsTheyOrderFromYou[]>(() => {
    if (!restaurant) return [];

    return restaurant?.itemsTheyOrderFromYou.map((i, index) => {
      return {
        key: index,
        ...i,
        price: formatPrice(i.price, restaurant.currency),
        lastMonthSales: formatPrice(i.lastMonthSales, restaurant.currency),
        yearTodaySales: formatPrice(i.yearTodaySales, restaurant.currency),
      };
    });
  }, [restaurant]);

  interface ItemsTheyDoNotOrderFromYou {
    key: number;
    name: string;
    category: string;
    price: string;
    expectedMonthlySales: string;
  }

  const itemsTheyDoNotOrderFromYouColumns: TableProps<ItemsTheyDoNotOrderFromYou>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text) => <TextCell value={text} />,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => <TextCell value={text} minWidth={70} />,
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      render: (text) => <TextCell value={text} minWidth={50} />,
    },
    {
      title: 'Expected Monthly Sales',
      dataIndex: 'expectedMonthlySales',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
  ];

  const itemsTheyDoNotOrderFromYouData = useMemo<ItemsTheyDoNotOrderFromYou[]>(() => {
    if (!restaurant) return [];

    return restaurant?.itemsYouHaveButOrderFromOthers.map((i, index) => {
      return {
        key: index,
        ...i,
        price: formatPrice(i.price, restaurant.currency),
        expectedMonthlySales: formatPrice(i.expectedMonthlySales, restaurant.currency),
      };
    });
  }, [restaurant]);

  interface ItemsYouDoNotHave {
    key: number;
    name: string;
    category: string;
    expectedMonthlySales: string;
  }

  const itemsYouDoNotHaveColumns: TableProps<ItemsYouDoNotHave>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text) => <TextCell value={text} />,
    },
    {
      title: 'Expected Monthly Sales',
      dataIndex: 'expectedMonthlySales',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
  ];

  const itemsYouDoNotHaveData = useMemo<ItemsYouDoNotHave[]>(() => {
    if (!restaurant) return [];

    return restaurant?.itemsYouDoNotHave.map((i, index) => {
      return {
        key: index,
        ...i,
        expectedMonthlySales: formatPrice(i.expectedMonthlySales, restaurant.currency),
      };
    });
  }, [restaurant]);

  return (
    <div className='flex gap-md'>
      {/* Left Side */}
      <div className='flex flex-col gap-2xl w-[300px]'>
        <div className='flex gap-md items-center'>
          <Image
            src={restaurant?.image}
            preview={false}
            width={60}
            fallback='https://placehold.co/400?text=KASO&font=roboto'
          />

          <div>
            <Typography.Paragraph
              style={{ minWidth: 200, maxWidth: 200 }}
              ellipsis={{
                rows: 1,
                suffix: '',
                tooltip: restaurant?.name,
              }}
              className='font-semibold text-lg !mb-0'>
              {restaurant?.name}
            </Typography.Paragraph>

            <p className='text-default-500'>{restaurant?.cuisineType}</p>
          </div>
        </div>

        <div className='flex justify-between items-center'>
          <p className='text-lg'>Total Sales</p>
          <p className='text-2xl font-semibold'>
            {formatPrice(restaurant?.salesVolume as number, restaurant?.currency)}
          </p>
        </div>

        <div>
          <Progress percent={restaurant?.salesRange.percentage} strokeColor={twoColors} showInfo={false} />
          <div className='flex justify-between'>
            <p>{formatPrice(restaurant?.salesRange?.lowestOrder as number, restaurant?.currency)}</p>
            <p>1M Range</p>
            <p>{formatPrice(restaurant?.salesRange?.highestOrder as number, restaurant?.currency)}</p>
          </div>
        </div>

        <div className='flex flex-col gap-sm'>
          <div
            className={cn('text-2xl font-semibold text-white', 'p-md rounded-md text-center', {
              'bg-green-500': restaurant?.isCustomer,
              'bg-red-500': !restaurant?.isCustomer,
            })}>
            {restaurant?.isCustomer && (
              <div className='flex items-center justify-center gap-2'>
                <p>It&apos;s a Customer</p>
                <RiEmotionHappyLine color='white' />
              </div>
            )}
            {!restaurant?.isCustomer && (
              <div className='flex items-center justify-center gap-2'>
                <p>Not a Customer</p>
                <RiEmotionUnhappyLine color='white' />
              </div>
            )}
          </div>

          {!restaurant?.isCustomer && (
            <div className='flex items-center justify-between'>
              <p className='text-[16px]'>Generate RFQ</p>
              <Button onClick={onOpenRfqModal}>Generate</Button>

              <Modal
                open={isOpenRfqModal}
                onCancel={onCloseRfqModal}
                title={
                  <div className='modal-header'>
                    <div className='modal-title'>Generate RFQ</div>
                  </div>
                }
                footer={
                  <div className='modal-footer'>
                    <Button type='default' onClick={onCloseRfqModal}>
                      Close
                    </Button>
                  </div>
                }>
                Coming Soon...
              </Modal>
            </div>
          )}

          {!!restaurant?.isCustomer && (
            <div className='flex items-center justify-between'>
              <p className='text-[16px]'>Assigned SAM</p>
              <Button onClick={onOpenSamModal}>Details</Button>

              <Modal
                open={isOpenSamModal}
                onCancel={onCloseSamModal}
                title={
                  <div className='modal-header'>
                    <div className='modal-title'>Assigned SAM</div>
                  </div>
                }
                footer={
                  <div className='modal-footer'>
                    <Button type='default' onClick={onCloseSamModal}>
                      Close
                    </Button>
                  </div>
                }>
                {!restaurant.sam?.name && (
                  <Alert message="Assigned SAM isn't found." type='warning' showIcon className='mb-2xl' />
                )}

                <div>
                  <h1 className='font-semibold text-md'>{restaurant.sam?.name}</h1>

                  <div>
                    <div className='flex gap-md items-center mt-2'>
                      <HiOutlinePhone size={18} />
                      <Typography.Text className='text-[14px]' copyable>
                        {restaurant?.sam?.phone || 'Not available'}
                      </Typography.Text>
                    </div>
                    <div className='flex gap-md items-center mt-2'>
                      <HiOutlineMail size={18} />
                      <Typography.Text className='text-[14px]' copyable>
                        {restaurant?.sam?.email || 'Not available'}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Supplier&apos;s Ratings</h1>

          <div className='flex items-center gap-xs'>
            <Rate disabled defaultValue={restaurant?.averageRating} />
            <p className='text-[14px]'>{`(${restaurant?.averageRating})`}</p>
            <Button className='ms-auto' onClick={onOpenReviewsModal}>
              Reviews
            </Button>

            <Modal
              open={isOpenReviewsModal}
              onCancel={onCloseReviewsModal}
              title={
                <div className='modal-header'>
                  <div className='modal-title'>Suppliers Review</div>
                </div>
              }
              footer={
                <div className='modal-footer'>
                  <Button type='default' onClick={onCloseReviewsModal}>
                    Close
                  </Button>
                </div>
              }>
              <div className='flex flex-col gap-lg'>
                {restaurant?.suppliersRating.map((r, index) => (
                  <div key={index} className='flex flex-col gap-2'>
                    <p className='font-semibold text-[16px]'>{r.supplierName}</p>

                    <div className='flex gap-xs items-center'>
                      <Rate disabled defaultValue={r?.rating} />
                      <p className='text-[14px]'>{`(${r?.rating})`}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Modal>
          </div>
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Price Sensitivity</h1>
          <div className='flex flex-col gap-sm'>
            <div className='flex items-center justify-between gap-md'>
              <p className='w-[80px] text-start'>Price</p>
              <Progress percent={(restaurant?.priceSensitivity.price as number) * 10} strokeColor={twoColors1} />
            </div>
            <div className='flex items-center justify-between gap-md'>
              <p className='w-[80px] text-start'>Quality</p>
              <Progress percent={(restaurant?.priceSensitivity.quality as number) * 10} strokeColor={twoColors1} />
            </div>
            <div className='flex items-center justify-between gap-md'>
              <p className='w-[80px] text-start'>Payment Terms</p>
              <Progress percent={(restaurant?.priceSensitivity.paymentTerms as number) * 10} strokeColor={twoColors1} />
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Info</h1>
          <div className='flex flex-col gap-0 divide-y-1'>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>No. of locations:</p>
              <p>{restaurant?.numberOfLocations}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>Items they use:</p>
              <p>{restaurant?.totalNumberOfRestaurantItems}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>Items they order from you:</p>
              <p>{restaurant?.totalNumberOfItemsTheyOrderFromYou}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>Items you have but they don&apos;t order from you:</p>
              <p>{restaurant?.totalNumberOfItemsYouHaveButOrderFromOthers}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>Items you don&apos;t have:</p>
              <p>{restaurant?.totalNumberOfItemsYouDoNotHave}</p>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-lg'>
          <h1 className='font-semibold text-lg'>Contact</h1>
          <div>
            <h1 className='font-semibold text-md'>Manager</h1>

            <div>
              <div className='flex gap-md items-center mt-2'>
                <HiOutlinePhone size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.manager.phone}
                </Typography.Text>
              </div>
              <div className='flex gap-md items-center mt-2'>
                <HiOutlineMail size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.manager.email}
                </Typography.Text>
              </div>
            </div>
          </div>

          <div>
            <h1 className='font-semibold text-md'>Sales</h1>
            <div>
              <div className='flex gap-md items-center mt-2'>
                <HiOutlinePhone size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.salesTeam.phone}
                </Typography.Text>
              </div>
              <div className='flex gap-md items-center mt-2'>
                <HiOutlineMail size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.salesTeam.email}
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Left Side */}

      {/* Divider */}
      <div className='border-[1px] border-default-200' />
      {/* Divider */}

      {/* Right Side */}
      <div className='flex flex-col gap-5xl flex-1' ref={rightSideElementRef}>
        <div>
          <RestaurantSalesChart chartData={restaurant?.chartData} />
        </div>

        <div className='flex flex-col gap-md'>
          <h1 className='font-semibold text-xl'>Items They Order From You</h1>
          <Table columns={itemsTheyOrderFromYouColumns} dataSource={itemsTheyOrderFromYouData} />
        </div>

        <div className='flex flex-col gap-md'>
          <h1 className='font-semibold text-xl'>Items They Don&apos;t Order From You</h1>
          <Table columns={itemsTheyDoNotOrderFromYouColumns} dataSource={itemsTheyDoNotOrderFromYouData} />
        </div>

        <div className='flex flex-col gap-md'>
          <h1 className='font-semibold text-xl'>Items You Don&apos;t Have</h1>
          <Table columns={itemsYouDoNotHaveColumns} dataSource={itemsYouDoNotHaveData} />
        </div>

        <div className='flex flex-col gap-md'>
          <h1 className='font-semibold text-xl'>Similar Restaurants</h1>
          <div className='flex items-start gap-md overflow-x-auto' style={{ width: rightSideWidth }}>
            {similarRestaurants?.map((r) => (
              <div
                key={r.id}
                className='border-1 rounded-md p-md cursor-pointer'
                onClick={() => onSimilarRestaurantClick(r)}>
                <div className='flex gap-md items-center'>
                  <Image
                    src={r?.image}
                    preview={false}
                    width={40}
                    fallback='https://placehold.co/400?text=KASO&font=roboto'
                  />

                  <div className='flex flex-col'>
                    <Typography.Text
                      style={{ minWidth: 150, maxWidth: 150 }}
                      ellipsis={{
                        suffix: '',
                        tooltip: r?.name,
                      }}
                      className='font-semibold text-md !mb-0'>
                      {r?.name}
                    </Typography.Text>
                    <p className='text-default-500'>{r.cuisineType}</p>
                  </div>
                </div>

                <div className='mt-md'>
                  <div className='flex flex-col gap-xs'>
                    <div className='flex items-center gap-xs'>
                      <Rate disabled defaultValue={r?.averageRating} />
                      <p>{`(${r?.averageRating})`}</p>
                    </div>

                    <div className='flex gap-2'>
                      <p>Is a customer:</p>
                      <Tag
                        color={r.isCustomer ? 'green' : 'red'}
                        bordered={true}
                        className='text-[10px] leading-[15px] px-[5px]'>
                        {r.isCustomer ? 'Yes' : 'No'}
                      </Tag>
                    </div>

                    <div className='flex gap-2'>
                      <p>No. of locations:</p>
                      <p>{r.numberOfLocations}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Right Side */}
    </div>
  );
};
