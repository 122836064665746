import orders from '../database/orders.json';
import restaurants from '../database/restaurants.json';
import { RestaurantSearch } from '../types';

/**
 * Fetch all restaurants whose name contains the search text (case insensitive).
 * @param searchText The search text to filter restaurants by.
 * @returns A list of restaurants that match the search text.
 */
export const searchRestaurantsByName = (searchText: string, supplierId: number): RestaurantSearch[] => {
  const trimmedName = searchText.trim().toLowerCase();

  const matchingRestaurants = restaurants.filter((restaurant) => restaurant.name.toLowerCase().includes(trimmedName));

  const updatedData = matchingRestaurants.map((r) => {
    const isCustomer = ((): boolean => {
      // Check if there's any order where both supplierId and restaurantId match
      const customerOrderExists = orders.some(
        (order) => order.supplierId === supplierId && order.restaurantId === r.id
      );

      return customerOrderExists;
    })();

    return {
      ...r,
      isCustomer,
    };
  });

  return updatedData;
};
