import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

interface RestaurantSalesChartProps {
  chartData?: {
    month: string;
    sales: number;
  }[];
}

export const RestaurantSalesChart = ({ chartData }: RestaurantSalesChartProps) => {
  return (
    <div>
      <ResponsiveContainer width='100%' height={400}>
        <AreaChart
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}>
          <defs>
            <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey='month' />
          <Tooltip />
          <Area type='monotone' dataKey='sales' stroke='#82ca9d' fillOpacity={1} fill='url(#colorPv)' />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
