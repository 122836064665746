import { SVGProps } from 'react';

export const BrandLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='113px' height='22px' viewBox='0 0 113 22' fill='#E53E51' {...props}>
      <path
        d='M16.3604 11.8743C20.4341 7.95883 22.4671 2.75872 22.4671 1.18495C22.4699 1.11273 22.4583 1.04066 22.4329 0.972977C22.4075 0.905289 22.3689 0.843332 22.3194 0.790721C22.2698 0.73811 22.2103 0.6959 22.1442 0.666556C22.0782 0.637212 22.0069 0.621322 21.9346 0.619812H17.2251C17.0661 0.620603 16.912 0.675213 16.788 0.774748C16.664 0.874283 16.5774 1.01288 16.5422 1.16795C15.7887 4.35473 12.3076 11.3654 5.57949 11.3654C5.49838 11.3654 5.44867 11.3654 5.44867 11.2764V1.34456C5.44867 1.15408 5.373 0.971398 5.23831 0.836709C5.10362 0.702019 4.92094 0.626351 4.73046 0.626351H0.718204C0.527725 0.626351 0.345047 0.702019 0.210357 0.836709C0.0756682 0.971398 1.19158e-06 1.15408 1.19158e-06 1.34456V20.6615C-0.000170805 20.7559 0.0182786 20.8494 0.0542947 20.9367C0.0903108 21.024 0.143186 21.1033 0.209894 21.1702C0.276603 21.237 0.355836 21.29 0.443058 21.3262C0.53028 21.3624 0.623778 21.381 0.718204 21.381H4.73177C4.8262 21.381 4.9197 21.3624 5.00692 21.3262C5.09414 21.29 5.17337 21.237 5.24008 21.1702C5.30679 21.1033 5.35966 21.024 5.39568 20.9367C5.4317 20.8494 5.45015 20.7559 5.44997 20.6615V16.5367C7.86142 16.5391 10.2359 15.944 12.3612 14.8047C14.1273 16.2633 15.5323 18.4245 16.7149 20.8315C16.8588 21.1259 17.0694 21.381 17.3978 21.381H22.0995C22.4069 21.381 22.7235 21.1089 22.6332 20.8158C21.2413 16.2999 18.6497 13.7058 16.3604 11.8743Z'
        fill='inherit'
      />
      <path
        d='M42.1817 0.929789C42.1383 0.872911 42.0878 0.821901 42.0313 0.778037C41.8062 0.609279 41.5001 0.604047 41.2176 0.605356L36.5342 0.63021C36.1417 0.63021 35.7493 0.609279 35.3647 0.63021C35.0402 0.653757 35.001 0.855221 34.8649 1.13387C34.5182 1.83768 25.4799 20.2768 25.4524 20.3331C25.3033 20.6392 25.179 21.067 25.4524 21.2684C25.5861 21.346 25.7407 21.3798 25.8946 21.3652L30.3686 21.3901C30.6284 21.4104 30.8894 21.3724 31.1326 21.2789C31.9306 20.8969 31.8142 19.2119 32.5926 18.7933C32.8987 18.6298 33.5672 18.6952 33.9139 18.7174C37.1517 18.928 40.1291 18.6036 43.0608 17.213C43.203 17.1228 43.3732 17.0875 43.5396 17.1136C43.7149 17.1659 43.8156 17.3451 43.8981 17.506L45.5699 20.8367C45.623 21.0211 45.7429 21.179 45.9062 21.2797C46.0695 21.3803 46.2645 21.4164 46.453 21.3809L51.2318 21.3692C51.4464 21.3692 51.6897 21.3548 51.8258 21.1899C52.005 20.9741 51.8886 20.6496 51.7643 20.3985C48.6364 14.0341 42.2615 1.03445 42.1817 0.929789ZM41.4936 12.4524C42.1281 13.8326 37.4696 14.2303 35.6695 14.1439C35.3673 14.1296 34.9709 13.7763 35.311 13.0974C35.9795 11.7604 37.3636 8.92553 38.0936 7.42763C38.1373 7.33847 38.2052 7.26335 38.2895 7.21082C38.3738 7.15828 38.4711 7.13043 38.5704 7.13043C38.6697 7.13043 38.7671 7.15828 38.8513 7.21082C38.9356 7.26335 39.0035 7.33847 39.0472 7.42763C39.7707 8.89151 41.0972 11.5916 41.4936 12.4524Z'
        fill='inherit'
      />
      <path
        d='M104.707 0.479858H89.82C84.2706 0.479858 81.0406 3.67318 81.0406 11.0004C81.0406 18.3277 84.2706 21.5197 89.82 21.5197H104.707C109.459 21.5197 112.557 17.5624 112.557 10.7924C112.557 4.02247 109.51 0.479858 104.707 0.479858ZM103.721 15.9245H90.8077C87.2756 15.9245 86.3925 14.6739 86.3925 10.9978C86.3925 7.32176 88.1691 6.07504 90.8077 6.07504H103.721C105.637 6.07504 107.515 7.06535 107.515 11.0004C107.515 14.9355 104.573 15.9245 103.721 15.9245Z'
        fill='inherit'
      />
      <path
        d='M77.8879 11.8206C77.8643 11.7801 77.8395 11.7395 77.8146 11.7003C76.8309 10.1422 74.9536 9.40305 73.1496 9.02629C69.4866 8.25837 65.6758 8.5815 61.9527 8.1524C61.4791 8.09746 60.9911 8.02159 60.5804 7.78349C60.1696 7.5454 59.8478 7.08753 59.9014 6.61265C59.993 5.79241 61.0081 5.48106 61.8284 5.38817C64.5111 5.08854 67.2197 5.10434 69.8987 5.43527C70.367 5.49414 70.8576 5.56609 71.2318 5.85912C71.755 6.26336 71.9774 7.02735 72.5923 7.26675C73.0214 7.43289 73.508 7.28376 73.9306 7.10977C75.2321 6.58098 76.4338 5.83371 77.4837 4.90021C77.6057 4.80819 77.7017 4.68602 77.7623 4.54569C77.8014 4.36981 77.7817 4.18595 77.706 4.02241C77.3136 2.97585 76.4776 2.14906 75.5109 1.59962C74.5441 1.05017 73.4518 0.758442 72.3607 0.543897C69.5062 -0.0160142 66.5746 -0.0735754 63.6703 0.0611695C62.2817 0.107154 60.8983 0.2545 59.5312 0.502033C58.8479 0.633157 58.1744 0.81061 57.5152 1.03316C56.9239 1.23463 56.2424 1.45179 55.7623 1.85341C55.0336 2.46303 54.4671 3.08443 54.0878 3.9727C53.729 4.83708 53.5424 5.76314 53.5383 6.69899C53.5252 7.77303 53.7084 8.87977 54.2827 9.78635C55.1369 11.1377 56.7159 11.8468 58.2648 12.2418C62.0586 13.2099 66.0591 12.7311 69.9353 13.257C70.4795 13.3303 71.0368 13.4297 71.5052 13.7188C71.9735 14.0079 72.3319 14.5325 72.2757 15.0793C72.2247 15.5673 71.8531 15.9755 71.4162 16.1992C70.9793 16.4229 70.4822 16.4948 69.9955 16.5524C67.3202 16.869 64.6031 16.903 61.9409 16.4961C61.2148 16.3849 60.4352 16.2083 59.9564 15.651C59.7876 15.4548 59.645 15.2036 59.3977 15.1277C59.1505 15.0519 58.9163 15.1748 58.6992 15.2873L54.6189 17.4066C54.4711 17.4681 54.3414 17.5662 54.2421 17.6918C54.1505 17.8638 54.1241 18.0631 54.1676 18.253C54.3402 19.471 55.3148 20.4574 56.4321 20.9728C57.5493 21.4882 58.8025 21.6151 60.0283 21.7185C64.5468 22.0965 69.1622 22.2588 73.5499 21.1167C75.1197 20.7085 76.7432 20.061 77.6563 18.724C78.2581 17.8436 78.4805 16.7617 78.5642 15.6942C78.6676 14.369 78.559 12.9653 77.8879 11.8206Z'
        fill='inherit'
      />
    </svg>
  );
};
